import { Box, FormControl, makeStyles, MenuItem, Radio, Select, TextField } from '@material-ui/core'
import { uploadForm2, uploadForm20 } from '@root-gipro/modules/userProjects/store/actions'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { ICompany, IState } from '@root-gipro/store/interfaces'
import { useRouteMatch } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Autocomplete } from '@material-ui/lab'

interface IDataMagazine {
	anchorEl: (EventTarget & HTMLElement) | null | undefined
	setAnchorEl(event: HTMLElement): void
	data: any
	title?: string
	startLoading: (mode: string) => void
	typeImport: string
}

const useStylesFormControl = makeStyles({
	root: {
		marginTop: 8,
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
		
	},
	autocompletecontainer: {
		width: '100%',
	},
})

const ContainerModal = styled.div``

const TitleChoice = styled.h2`
	margin: 12px auto 4px;
	font-size: 18px;
	display: flex;
	justify-content: left;
`
const ContainerRadio = styled.div`
	margin: 8px 0;
`
const NameRadio = styled.span`
	margin-left: 8px;
	font-size: 14px;
	vertical-align: middle;
	cursor: pointer;
`
const ContainerButton = styled.div`
	position: relative;
	height: 20px;
`

const PositionButton = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
	top: 10px;
`

const LabelButtonAdd = styled.label`
	color: #fff;
	width: 100px;
	border: 1px solid #3f4f5e;
	cursor: pointer;
	height: 32px;
	display: flex;
	justify-content: center;
	padding: 0 8px;
	font-size: 13px;
	min-width: 100px;
	background-color: #3f4f5e;
	text-align: center;
	border-radius: 4px;
	align-items: center;
	&:hover {
		color: rgba(0, 0, 0, 0.87);
		background-color: #f7f7f7;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
			box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
`

const SelectChoiceImport: FC<IDataMagazine> = ({ anchorEl, setAnchorEl, data, title, startLoading, typeImport }) => {
	const classesFromControl = useStylesFormControl()
	const { company } = useSelector((state: IState) => state.public)
	const [selectedCompany, setSelectedCompany] = useState<any>()
	const [isSelectedCompany, setIsSelectedCompany] = useState(false)

	const dispatch = useDispatch()
	const [formVersion, setFormVersion] = useState('2024')
	const [importLocation, setImportLocation] = useState('calculations')
	const [keyImport, setKeyImport] = useState<number>(0)
	const routeMatch = useRouteMatch()
	const url = routeMatch.path.slice(1)
	const handleFileChange = (e: any) => {
		if (typeImport === 'import-form-2') {
			dispatch(uploadForm2(e.target.files, importLocation, formVersion, selectedCompany, url))
		} else if (typeImport === 'import-form-20') {
			dispatch(uploadForm20(e.target.files, importLocation, formVersion, selectedCompany, url))
		} else if (typeImport === 'import-form-new-20') {
			dispatch(uploadForm20(e.target.files, importLocation, formVersion, selectedCompany, url, typeImport))
		}
		setKeyImport(keyImport + 1)
		setAnchorEl(null!)
		startLoading('import')
	}

	const handleSelectCompany = (company: ICompany | null) => {
		setSelectedCompany(company?.id)
	}
	useEffect(() => {
		if (selectedCompany) {
			setIsSelectedCompany(true)
		} else {
			setIsSelectedCompany(false)
		}
	}, [selectedCompany])

	return (
		<Modal setAnchorEl={setAnchorEl} anchorEl={anchorEl} widthWindow='400px'>
			<ContainerModal>
				<Box style={{ marginBottom: 12 }}>
					<div>
						<TitleChoice>Версия импортируемой формы</TitleChoice>
						<ContainerRadio>
							<Radio
								checked={formVersion === '2018'}
								value='2018'
								onChange={e => setFormVersion(e.target.value)}
								color='primary'
							/>
							<NameRadio onClick={e => setFormVersion('2018')}>УНЦ-2019</NameRadio>
						</ContainerRadio>
						<ContainerRadio>
							<Radio
								value='2024'
								checked={formVersion === '2024'}
								onChange={e => setFormVersion(e.target.value)}
								color='primary'
							/>
							<NameRadio onClick={e => setFormVersion('2024')}>УНЦ-2024</NameRadio>
						</ContainerRadio>
					</div>
					<div>
						<TitleChoice>Куда импортировать форму?</TitleChoice>
						<ContainerRadio>
							<Radio
								value='projects'
								checked={importLocation === 'projects'}
								onChange={e => setImportLocation(e.target.value)}
								color='primary'
							/>
							<NameRadio onClick={() => setImportLocation('projects')}>Проекты</NameRadio>
						</ContainerRadio>
						<ContainerRadio>
							<Radio
								value='calculations'
								checked={importLocation === 'calculations'}
								onChange={e => setImportLocation(e.target.value)}
								color='primary'
							/>
							<NameRadio onClick={() => setImportLocation('calculations')}>Расчеты</NameRadio>
						</ContainerRadio>
					</div>
					<div>
						<TitleChoice>В какую компанию импортировать форму?</TitleChoice>
						<FormControl fullWidth classes={classesFromControl}>
							<Autocomplete
								className={classesFromControl.autocompletecontainer}
								options={company.slice().sort((a, b) => a?.name?.localeCompare(b?.name))}
								getOptionLabel={option => option.name}
								onChange={(event, newValue) => handleSelectCompany(newValue || null)}
								value={selectedCompany}
								renderInput={params => (
									<TextField
										{...params}
										variant='outlined'
										fullWidth
										InputProps={{
											...params.InputProps,
											style: {
												boxSizing: 'border-box',
												overflow: 'hidden',
											},
										}}
										inputProps={{
											...params.inputProps,
											style: {
												padding: '20px', 
												overflow: 'hidden',
											},
										}}
									/>
								)}
								getOptionSelected={(option, value) => option.id === value.id}
							/>
						</FormControl>
					</div>
				</Box>
				<ContainerButton>
					<PositionButton>
						<SecondaryBtn onClick={() => setAnchorEl(null!)}>Отмена</SecondaryBtn>
						<LabelButtonAdd htmlFor='file-input'>
							<input
								type='file'
								id='file-input'
								key={keyImport}
								accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								onChange={handleFileChange}
								style={{ display: 'none' }}
								disabled={!isSelectedCompany}
							/>
							Ок
						</LabelButtonAdd>
					</PositionButton>
				</ContainerButton>
			</ContainerModal>
		</Modal>
	)
}

export default SelectChoiceImport
