import {
	createStyles,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { SwapVertOutlined } from '@material-ui/icons'
import { ModalContext } from '@root-gipro/context/modal-context'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { deleteUserFetch } from '@root-gipro/modules/AdminPanel/store/actions'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AdminPanelTableUserRow from './AdminPanelTableUserRow'
import ModalDeleteUser from './ModalsAdminPanel/ModalDeleteUser'

interface IAdminPanelTable {
	users: IUser[]
	searchText: string
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
		sortIcon: {
			width: '13px',
			verticalAlign: 'bottom',
			marginLeft: '5px',
		},
	})
)

const sortUsers = (key: string, ascending: boolean) => (x: IUser, y: IUser) => {
	let result
	if (key === 'id') {
		result = x.id - y.id
	} else {
		const xKey = x[key]?.toString().toLowerCase() ?? ''
		const yKey = y[key]?.toString().toLowerCase() ?? ''
		result = xKey.localeCompare(yKey)
	}
	return ascending ? result : -result
}

const AdminPanelTable: FC<IAdminPanelTable> = ({ users, searchText }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	const [sortedUsers, setSortedUsers] = useState<IUser[]>(users)
	const [sortKey, setSortKey] = useState<string>('lastname')
	const [ascending, setAscending] = useState<boolean>(true)
	const [userInfo, setUserInfo] = useState<IUser>()

	useEffect(() => {
		const filteredUsers = users.filter(user =>
			`${user.lastname} ${user.firstname} ${user.middlename}`.toLowerCase().includes(searchText.toLowerCase())
		)
		setSortedUsers([...filteredUsers].sort(sortUsers(sortKey, ascending)))
	}, [users, searchText, sortKey, ascending])

	const handleSortChange = (key: string) => {
		if (sortKey === key) {
			setAscending(!ascending)
		} else {
			setSortKey(key)
			setAscending(true)
		}
	}

	const deleteUser = () => {
		if (userInfo) {
			dispatch(deleteUserFetch(userInfo.id))
		}
	}

	return (
		<TableContainer component={Paper} className={classes.root}>
			<div style={{ width: '100%', overflowX: 'auto' }}>
				<Table size='small' stickyHeader style={{ minWidth: 1800 }} aria-label='admin panel table'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: 100 }} align='center'>
								Действия
							</TableCell>
							<TableCell style={{ width: 70, cursor: 'pointer' }} onClick={() => handleSortChange('id')}>
								ID <SwapVertOutlined className={classes.sortIcon} />
							</TableCell>
							<TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortChange('lastname')}>
								ФИО <SwapVertOutlined className={classes.sortIcon} />
							</TableCell>
							<TableCell style={{ width: 215 }}>Дата лицензии</TableCell>
							<TableCell style={{ minWidth: 185 }}>Роль</TableCell>
							<TableCell>Компания</TableCell>
							<TableCell style={{ width: 150 }} align='center'>
								Связанные ИПР
							</TableCell>
							<TableCell>Филиал</TableCell>
							<TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortChange('email')}>
								Почта <SwapVertOutlined className={classes.sortIcon} />
							</TableCell>
							<TableCell style={{ minWidth: 170 }}>Телефон</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedUsers.length ? (
							sortedUsers.map((user, index) => (
								<AdminPanelTableUserRow setUserInfo={setUserInfo} user={user} index={index} key={user.id} />
							))
						) : (
							<TableRow>
								<TableCell colSpan={10} align='left'>
									Нет данных для отображения
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			{anchorEl?.dataset.popup === 'delete_user' && (
				<ModalDeleteUser anchorEl={anchorEl} user={userInfo} setAnchorEl={setAnchorEl!} handleDelete={deleteUser} />
			)}
		</TableContainer>
	)
}

export default AdminPanelTable
