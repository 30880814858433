import { setShowWarningUncIds } from '@root-gipro/modules/userProjects/store/actions'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import CoefficentSelect from './CoefficientSelect'
import './Comment.scss'

export interface CommentProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	setObjectName(objectName: string): void
	setStage(stage: string): void
	setKf(kf: any): void
	setDescription(description: string): void
	setComment(comment: string): void
	objectName: string | null
	stage: string | null
	kf: any
	description: string | null
	comment: string | undefined
	handler(): void
	isArchived?: boolean
	uncSort: string | null
	setComplicatingParams: (mode: any) => void
	complicatingParams: any
	id: any
	complicatingSelects: any
	coefficentUpdate: (complicatingParams: any) => void
}

const CommentDropDown: React.FC<CommentProps> = ({
	anchorEl,
	setAnchorEl,
	setObjectName,
	setStage,
	setKf,
	setDescription,
	setComment,
	objectName,
	stage,
	kf,
	description,
	comment,
	handler,
	isArchived,
	uncSort,
	setComplicatingParams,
	complicatingParams,
	id,
	complicatingSelects,
	coefficentUpdate,
}) => {
	const dispatch = useDispatch()
	const kfRef = useRef<HTMLInputElement>(null)
	const kfRef1 = useRef<HTMLInputElement>(null)
	const kfRef2 = useRef<HTMLInputElement>(null)
	const kfRef3 = useRef<HTMLInputElement>(null)

	const [kfResult, setKfResult] = useState(1)

	useEffect(() => {
		if (uncSort == '2024') {
			const newKf = kf.kf1 * kf.kf2 * kf.kf3
			setKfResult(newKf)
		}
	}, [kf])

	const position = useRef<number>(1)
	const resullt = useMemo(() => {
		return kf
	}, [kf])
	useEffect(() => {
		if (kfRef.current !== null) {
			kfRef.current.setSelectionRange(position.current, position.current)
		}
		if (kfRef1.current !== null) {
			kfRef1.current.setSelectionRange(position.current, position.current)
		}
		if (kfRef2.current !== null) {
			kfRef2.current.setSelectionRange(position.current, position.current)
		}
		if (kfRef3.current !== null) {
			kfRef3.current.setSelectionRange(position.current, position.current)
		}
	}, [resullt])

	return (
		<DropDown
			anchorEl={anchorEl}
			anchorReference='anchorPosition'
			anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
			setAnchorEl={setAnchorEl}
			style={{
				borderRadius: 9,
				width: 800,
				background: 'rgba(255, 255, 255, 0.93)',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
			}}
		>
			<div className='comment'>
				<div className='cell_wide'>
					<div className='unc_comment_title'>
						Наименование одного объекта, где реализуется технологическое решение (мероприятие)
					</div>
					<textarea
						placeholder={!isArchived ? 'Введите' : ''}
						disabled={isArchived}
						value={objectName ? objectName : ''}
						onChange={e => setObjectName(e.currentTarget.value)}
					/>
				</div>

				<div className='check-plate-row row-cell'>
					<div className='cell_wide'>
						<div className='unc_comment_title'>Номер этапа строительства</div>
						<div className='full-width'>
							<input
								className='universal-input'
								type='text'
								value={stage || ''}
								disabled={isArchived}
								placeholder='Введите'
								onChange={e => setStage(e.currentTarget.value)}
							/>
						</div>
					</div>
				</div>
				<div className='check-plate-row row-cell'>
					<div className='cell_wide'>
						<div className='unc_comment_title'>Результирующий коэффициент УНЦ </div>
						<div className='full-width'>
							<input
								ref={kfRef}
								className='universal-input'
								step='0.1'
								value={Number(kfResult).toFixed(13)}
								disabled={uncSort == '2024'}
								placeholder='Введите'
								onChange={e => {
									const validVal = e.currentTarget.value.replace(/[^\d.]/g, '')
									setKf((prev: any) => {
										return { ...prev, kf: validVal }
									})
									position.current = kfRef.current?.selectionStart as number
								}}
							/>
						</div>
					</div>
				</div>
				{complicatingSelects && (
					<div className='cell_wide'>
						<div className='unc_comment_title'> {complicatingSelects?.title} </div>
						<div className='full-width'>
							<CoefficentSelect
								selectsUncComplicatingType={complicatingSelects?.selects_type === 'Checkbox'}
								selectsUncComplicating={complicatingSelects}
								handler={handler}
								setComplicatingParams={setComplicatingParams}
								complicatingParams={complicatingParams}
								id={id}
								coefficentUpdate={coefficentUpdate}
							/>
						</div>
					</div>
				)}
				{uncSort == '2024' && (
					<div className='check-plate-row row-cell coeff-row'>
						<div className='cell_wide'>
							<div className='unc_comment_title'>Кф1</div>
							<div className='full-width'>
								<input
									ref={kfRef1}
									className='universal-input'
									step='0.1'
									value={Number(kf.kf1).toFixed(13)}
									disabled={isArchived}
									placeholder='Введите'
									onChange={e => {
										const validVal = e.currentTarget.value.replace(/[^\d.]/g, '')
										setKf((prev: any) => {
											return { ...prev, kf1: validVal }
										})
										position.current = kfRef1.current?.selectionStart as number
									}}
								/>
							</div>
						</div>
						<div className='cell_wide'>
							<div className='unc_comment_title'>Кф2</div>
							<div className='full-width'>
								<input
									ref={kfRef2}
									className='universal-input'
									step='0.1'
									value={Number(kf.kf2).toFixed(13)}
									disabled={isArchived}
									placeholder='Введите'
									onChange={e => {
										const validVal = e.currentTarget.value.replace(/[^\d.]/g, '')
										setKf((prev: any) => {
											return { ...prev, kf2: validVal }
										})
										position.current = kfRef2.current?.selectionStart as number
									}}
								/>
							</div>
						</div>
						<div className='cell_wide'>
							<div className='unc_comment_title'>Кф3</div>
							<div className='full-width'>
								<input
									ref={kfRef3}
									className='universal-input'
									step='0.1'
									value={Number(kf.kf3).toFixed(13)}
									disabled={isArchived}
									placeholder='Введите'
									onChange={e => {
										const validVal = e.currentTarget.value.replace(/[^\d.]/g, '')
										setKf((prev: any) => {
											return { ...prev, kf3: validVal }
										})

										position.current = kfRef3.current?.selectionStart as number
									}}
								/>
							</div>
						</div>
					</div>
				)}
				<div className='cell_wide'>
					<div className='unc_comment_title'>Краткое обоснование корректировки утвержденного плана</div>
					<textarea
						placeholder={!isArchived ? 'Введите' : ''}
						disabled={isArchived}
						value={description ? description : ''}
						onChange={e => setDescription(e.currentTarget.value)}
					/>
				</div>
				<div className='cell_wide'>
					<div className='unc_comment_title'>Комментарий</div>
					<textarea
						placeholder={!isArchived ? 'Введите комментарий' : ''}
						disabled={isArchived}
						value={comment ? comment : ''}
						onChange={e => setComment(e.currentTarget.value)}
					/>
				</div>
				{!isArchived ? (
					<div className='action mt-16'>
						<PrimaryBtn
							onClick={() => {
								handler()
								dispatch(setShowWarningUncIds(id))
							}}
						>
							Готово
						</PrimaryBtn>
						<SecondaryBtn onClick={() => setAnchorEl!(null!)}>Отменить</SecondaryBtn>
					</div>
				) : null}
			</div>
		</DropDown>
	)
}
export default CommentDropDown
